<script>
export default {
  name: 'About',
  props: {
    isLiffOpen : {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <main>
    <!-- First View -->
    <section class="concept">
      <div class="concept_container">
        <h1>{{ $t('aboutTitle1') }}<br><span class="h1_secondLine">{{ $t('aboutTitle2') }}</span></h1>
        <img src="@/assets/about/karte.png">
      </div>
    </section>
    <!-- 米国皮膚科学会 -->
    <section class="quotation">
      <div class="gradationZone_upper" />
      <div class="quoteAad_container">
        <div class="quotedAad clearfix">
          <p>“</p>
          <p>
            <span class="emphasize">{{ $t('aboutIntro1') }}</span>{{ $t('aboutIntro2') }}<br>
            <span class="emphasize">{{ $t('aboutIntro3') }}</span>{{ $t('aboutIntro4') }}
          </p>
          <p>”</p>
          <p>{{ $t('aboutIntro5') }}</p>
        </div>
      </div>
      <div class="gradationZone_lower" />
    </section> 
    <!-- problem presentation -->
    <section class="question">
      <p class="however">
        {{ $t('aboutChangeTopics') }}
        <span></span>
        <span></span>
        <span></span>
      </p>
      <div class="q_yourSkinType_container">
        <div class="question1">?</div>
        <div class="q_yourSkinType">
          <p>{{ $t('aboutQuestion1') }}<br>{{ $t('aboutQuestion2') }}</p>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="clearboth"></div>
      <div class="q_knowItem_container">
        <div class="question2">?</div>
        <div class="q_knowItem">
          <p>{{ $t('aboutQuestion3') }}<br>{{ $t('aboutQuestion4') }}</p>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="clearboth"></div>
      <div class="q_knowSkincare_container">
        <div class="question3">?</div>
        <div class="q_knowSkincare">
          <p>{{ $t('aboutQuestion5') }}<br>{{ $t('aboutQuestion6') }}</p>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="clearboth"></div>
      <div class="q_continue">
        <p>
          {{ $t('aboutContinueTopics') }}
          <span></span>
          <span></span>
          <span></span>
        </p>
      </div>
      <div class="q_skinChange">
        <!-- TODO: デザインを変更する -->
        <!-- use v-if to show the profile by language -->
        <p v-if="$i18n.locale == 'ja'">
          <span class="skinChange_emphasize">{{ $t('aboutQuestion7') }}</span>{{ $t('aboutComma') }}
          <span class="skinChange_emphasize">{{ $t('aboutQuestion8') }}</span>{{ $t('aboutComma') }}<br>
          <span class="skinChange_emphasize">{{ $t('aboutQuestion9') }}</span>{{ $t('aboutQuestion10') }}<br>
          {{ $t('aboutQuestion11') }}<span class="skinChange_emphasize_red">{{ $t('aboutQuestion12') }}</span>{{ $t('aboutQuestion13') }}
        </p>
        <p v-if="$i18n.locale == 'en'">
          Skin is constantly changing due to the seasons, skin care, and lifestyle.
        </p>
        <p v-if="$i18n.locale == 'zh'">
          根据季节，皮肤保养，生活习惯等皮肤经常变化。
        </p>
      </div>
    </section>
    <!-- Catchphrase -->
    <section class="yuni-cosme">
      <div class="arrow_a">
        <span></span>
      </div>
      <div class="yuni-cosme_imgContainer">
        <img src="@/assets/about/karte.png" class="karte">
        <h2>
          <span class="english">{{ $t('serviceTitle') }}</span>{{ $t('aboutCatchCopy1') }}<br>
          {{ $t('aboutCatchCopy2') }}
        </h2>
      </div>
      <div class="arrow_a">
        <span></span>
      </div>
    </section>
    <!-- What you can do -->
    <section class="whatYouCan">
      <div class="whatYouCan_title">
        <p>
          <span class="english">{{ $t('serviceTitle') }}</span>{{ $t('aboutCanDoTitle1') }}<br>
          {{ $t('aboutCanDoTitle2') }}
        </p>
      </div>
      <div class="service_explanation_container">
        <div class="service_explanation_card">
          <div class="service_explanation_iconBox">
            <img src="@/assets/logos/skin_check.svg">
            <p>{{ $t('aboutCanDoDesc1') }}</p>
          </div>
          <div class="service_explanation">
            <p>{{ $t('aboutCanDoDesc2') }}</p>
          </div>
        </div>
        <div class="service_explanation_card">
          <div class="service_explanation_iconBox">
            <img src="@/assets/logos/skincare_suggestion.svg">
            <p>{{ $t('aboutCanDoDesc3') }}</p>
          </div>
          <div class="service_explanation">
            <p>{{ $t('aboutCanDoDesc4') }}</p>
          </div>
        </div>
        <div class="service_explanation_card">
          <div class="service_explanation_iconBox">
            <img src="@/assets/logos/makeup_suggestion.svg">
            <p>{{ $t('aboutCanDoDesc5') }}</p>
          </div>
          <div class="service_explanation">
            <p>{{ $t('aboutCanDoDesc6') }}</p>
          </div>
        </div>
        <!-- coming soon -->
        <div class="service_explanation_card coming_soon">
          <div class="service_explanation_iconBox">
            <img src="@/assets/logos/ingredientcheck_icon.svg">
            <p>{{ $t('aboutCanDoDesc7') }}</p>
          </div>
          <div class="service_explanation">
            <p>{{ $t('aboutCanDoDesc8') }}</p>
          </div>
          <img
            src="@/assets/about/coming_soon.png"
            class="coming_soon_card"
          >
        </div>
      </div>
      <div class="arrow_b">
        <span></span>
      </div>
      <div class="arrow_b">
        <span></span>
      </div>
      <!-- Mykarte -->
      <div class="mykarte">
        <div class="mykarte_explanation">
          <h2>{{ $t('aboutMykarteDesc1') }}</h2>
          <p>{{ $t('aboutMykarteDesc2') }}<br>{{ $t('aboutMykarteDesc2') }}</p>
        </div>
        <div class="mykarte_contents_container">
          <img src="@/assets/about/karte.png">
          <div class="mykarte_contents_inner">
            <div class="mykarte_contents">
              <div class="mykarte_contents_imgBox">
                <img src="@/assets/logos/skin_check.svg">
              </div>
              <p>{{ $t('aboutMykarteDesc4') }}</p>
            </div>
            <div class="mykarte_contents">
              <div class="mykarte_contents_imgBox">
                <img src="@/assets/logos/skincare_suggestion.svg">
              </div>
              <p>{{ $t('aboutMykarteDesc5') }}</p>
            </div>
            <div class="mykarte_contents">
              <div class="mykarte_contents_imgBox">
                <img src="@/assets/logos/makeup_suggestion.svg">
              </div>
              <p>{{ $t('aboutMykarteDesc6') }}<br>{{ $t('aboutMykarteDesc7') }}</p>
            </div>
            <div class="mykarte_contents">
              <div class="mykarte_contents_imgBox">
                <img src="@/assets/logos/ingredientcheck_icon.svg">
              </div>
              <p>{{ $t('aboutMykarteDesc8') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mykarte_comment_container">
        <div class='mykarte_comment'>
          <p>
            {{ $t('aboutMykarteDesc9') }}<br>
            {{ $t('aboutMykarteDesc10') }}<br>
            <span>{{ $t('aboutMykarteDesc11') }}</span><br>
            {{ $t('aboutMykarteDesc12') }}
          </p>
        </div>
      </div>
      <div class="arrow_c">
        <span></span>
      </div>
    </section>
    <!-- First of All -->
    <section class="skinCheck">
      <div class="atFirst">
        <p>
          {{ $t('aboutFirstOfAll1') }}
          <span></span>
          <span></span>
          <span></span>
        </p>
      </div>
      <div class="skinCheck_container">
        <img
          src="@/assets/about/karte.png"
          class="skinCheck_img"
        >
        <div class="skinCheck_contents_inner">
          <router-link to="/flow/skin-check" class="skinCheck_btn_link">
            <div class="skinCheck_contents">
              <div class="skinCheck_contents_imgBox">
                <img
                  src="@/assets/logos/skin_check.svg"
                  class="skinCheck_btn_img"
                >
              </div>
              <p class="skinCheck_btn_title skinCheck_btn">
                {{ $t('aboutFirstOfAll2') }}
                <img src="@/assets/about/arrow.png">
              </p>
            </div>
          </router-link>
          <div class="skinCheck_contents">
            <div class="skinCheck_contents_imgBox">
              <img src="@/assets/logos/skincare_suggestion.svg">
            </div>
            <p class="skinCheck_btn_title">{{ $t('aboutFirstOfAll3') }}</p>
          </div>
          <div class="skinCheck_contents">
            <div class="skinCheck_contents_imgBox">
              <img src="@/assets/logos/makeup_suggestion.svg">
            </div>
            <p class="skinCheck_btn_title">{{ $t('aboutFirstOfAll4') }}</p>
          </div>
          <div class="skinCheck_contents">
            <div class="skinCheck_contents_imgBox">
              <img src="@/assets/logos/ingredientcheck_icon.svg">
            </div>
            <p class="skinCheck_btn_title">{{ $t('aboutFirstOfAll5') }}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- Our Promise -->
    <section class="ourPromise">
      <div class="ourPromise_title">
        <p>{{ $t('aboutOurPromise1') }}</p>
      </div>
      <!-- TODO: デザインを変更する -->
      <!-- use v-if to show the profile by language -->
      <div
        class="ourPromise_main"
        v-if="$i18n.locale == 'ja'"
      >
        <p>
          <span class="ourPromise_firstLine">
            <span class="ourPromise_bold">{{ $t('aboutOurPromise2') }}</span>
            {{ $t('aboutOurPromise3') }}
          </span><br>
          <span class="ourPromise_secondLine">
            <span class="ourPromise_bold">{{ $t('aboutOurPromise4') }}</span>
            {{ $t('aboutOurPromise5') }}
          </span>
        </p>
      </div>
      <p v-else-if="$i18n.locale == 'en'">
        We do not receive advertising fees from manufacturers or brands.
      </p>
      <p v-else-if="$i18n.locale == 'zh'">
        我们不从制造商或品牌那里收取广告费。
      </p>

      <div class="ourPromise_Explanation">
        <p>
          {{ $t('aboutOurPromiseDesc1') }}
          {{ $t('aboutOurPromiseDesc2') }}<br>
          {{ $t('aboutOurPromiseDesc3') }}
          {{ $t('aboutOurPromiseDesc4') }}
        </p>
      </div>
    </section>
    <!-- Float button -->
    <router-link to="/flow/skin-check">
      <button
        class="float--button"
      >
        <div class="float--button__wrapper">
          <img
            src="@/assets/about/concierges_white.svg"
            class="float--button__img"
            alt=""
          >
          <p class="float--button__text">
            {{ $t('aboutStartSkinCheck1') }}<br>
            {{ $t('aboutStartSkinCheck2') }}
          </p>
        </div>
      </button>
    </router-link>
  </main>
</template>

<style scoped>
main{
  font-size: 14px;
  display: block;
  max-width: 414px;
  margin: 0 auto;
  background-color: #F5EAE7;
}
h1{
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  writing-mode: vertical-rl;
  text-align: center;
  margin: 0 auto;
  padding: 30px 0 30px;
  letter-spacing: 0.6em;
  line-height: 150%;
}
.h1_secondLine{
  color: #F06247;
  padding-top: 150px;
  white-space: nowrap;
}
h2{
  font-size: 18px;
}
h3{
  font-size: 20px;
  padding-bottom: 80px;
  text-align: center;
}
h3 span{
  font-weight: bold;
}
a{
  text-decoration: none;
}
.english{
  font-family: "BrandonGrotesque Bold";
  letter-spacing: -1px;
}
.clearboth{
  clear: both;
}
.emphasize{
  font-size: 20px;
  font-weight: bold;
}
.clearfix::after{
  content: "";
  clear: both;
  display: block;
}
/* ここからコンセプトセクション */
.concept{
  margin: 0 0 150px;
}
.concept_container {
  text-align: center;
}
.concept_container img{
  display: block;
  width: 80px;
  margin: 0 auto;
}
.float--button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  background-color: #F29F8F;
  border-radius: 50%;
  text-align : center;
  z-index: 999;
}
.float--button__wrapper {
  /* todo: 明日確認する */
  position: absolute;
  transform: translateY(-50%) translateX(20%);
}
.float--button__img {
  width: 50px;
  height: 50px;
}
.float--button__text {
  font-size: 12px;
  color: #fff;
  padding-top: 2px;
}
.float--button:hover {
  opacity: .8; /* マウスが重なった時に少し透過させます */
}
/* ここからquotstionセクション */
.quotation{
  background-color: #fff;
}
.gradationZone_upper{
  width: 100%;
  height: 50px;
  background: linear-gradient(#F5EAE7, #fff);
}
.gradationZone_lower{
  width: 100%;
  height: 50px;
  background: linear-gradient(#fff,#F5EAE7);
}
.quoteAad_container{
  position: relative;
  padding: 100px 0;
}
.quotedAad{
  width: 80%;
  margin: auto;
}
.quotedAad p:nth-child(1){
  font-size: 2em;
  font-weight: bold;
}
.quotedAad p:nth-child(2){
  font-size: 14px;
  line-height: 32px;
}
.quotedAad p:nth-child(3){
  font-size: 2em;
  font-weight: bold;
  float: right;
  clear: both;
}
.quotedAad p:nth-child(4){
  float: right;
  clear: both;
}
/* questionセクション */
.question{
  position: relative;
  padding: 200px 0 300px 0;
}
.however{
  writing-mode: vertical-rl;
  margin: 0 auto;
  font-size: 36px;
  font-weight: 500;
  padding-bottom: 300px;
  letter-spacing: 10px;
  white-space: nowrap;
}
.however span {
  display: inline-block;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #777;
  border-radius: 50%;
  margin: 6px;
}
.q_yourSkinType{
  position: relative;
  float: right;
  padding: 0 25px 0 0;
}
.q_yourSkinType p{
  color: #fff;
  font-size: 20px;
  background-color: #F29F8F;
  padding: 20px 25px;
  border-radius: 15px;
  line-height: 1.5em;
}
.q_yourSkinType_container{
  position: relative;
}
.question1{
  position: absolute;
  font-size: 300px;
  transform: rotate(-10deg);
  color: #777;
  opacity: 0.1;
  font-family: 'HiraMinProN-W3';
  top: -150px;
  left: -10px;
}
.q_yourSkinType span:nth-of-type(1){
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #F29F8F;
  border-radius: 50%;
  right: 80px;
  bottom: -15px;
}
.q_yourSkinType span:nth-of-type(2){
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #F29F8F;
  border-radius: 50%;
  right: 65px;
  bottom: -30px;
}
.q_knowItem_container{
  position: relative;
  padding: 150px 0 150px 25px;
  overflow: hidden;
}
.question2{
  position: absolute;
  font-size: 300px;
  transform: rotate(15deg);
  color: #777;
  opacity: 0.1;
  font-family: 'HiraMinProN-W3';
  bottom: -10px;
  right: -30px;
}
.q_knowItem{
  position: relative;
  float: left;
}
.q_knowItem p{
  color: #fff;
  font-size: 20px;
  background-color: #F29F8F;
  padding: 20px 25px;
  border-radius: 15px;
  line-height: 1.5em;
}
.q_knowItem span:nth-of-type(1){
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #F29F8F;
  border-radius: 50%;
  left: 80px;
  bottom: -15px;
}
.q_knowItem span:nth-of-type(2){
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #F29F8F;
  border-radius: 50%;
  left: 65px;
  bottom: -30px;
}
.q_knowSkincare_container{
  position: relative;
  margin: 0 0 0 25px;
}
.question3{
  position: absolute;
  font-size: 300px;
  transform: rotate(-30deg);
  color: #777;
  opacity: 0.1;
  font-family: 'HiraMinProN-W3';
  top: -120px;
  left: -10px;
}
.q_knowSkincare{
  position: relative;
  float: right;
}
.q_knowSkincare p{
  color: #fff;
  font-size: 20px;
  background-color: #F29F8F;
  padding: 20px 25px;
  border-radius: 15px;
  line-height: 1.5em;
}
.q_knowSkincare span:nth-of-type(1){
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #F29F8F;
  border-radius: 50%;
  right: 80px;
  bottom: -15px;
}
.q_knowSkincare span:nth-of-type(2){
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #F29F8F;
  border-radius: 50%;
  right: 65px;
  bottom: -30px;
}
.q_continu p{
  line-height: 2em;
}
.q_continue{
  width: 100vw;
  max-width: 414px;
  padding: 350px 0 200px 0;
  white-space: nowrap;
}
.q_continue p{
  writing-mode: vertical-rl;
  color: #777;
  font-weight: 500;
  font-size: 36px;
  letter-spacing: 10px;
  margin: 0 auto;
}
.q_continue span {
  display: inline-block;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #777;
  border-radius: 50%;
  margin: 6px;
}
.q_skinChange{
  color: #777;
  font-size: 24px;
  text-align: center;
  line-height: 45px;
}
.q_skinChange p{
  line-height: 2em;
}
.skinChange_emphasize{
  font-weight: bold;
  font-size: 36px;
}
.skinChange_emphasize_red{
  font-weight: bold;
  font-size: 36px;
  color: #F06247;
}
/* ここからyuni-cosmeセクション */
.arrow_a:first-child{
  position: relative;
  padding: 0 0 200px 0;
}
.arrow_a:last-child{
  position: relative;
  padding: 200px 0 10px 0;
}
.arrow_a span{
  display: block;
	vertical-align: middle;
	position: absolute;
  left: 50%;
}
.arrow_a span::before, .arrow_a span::after{
  content: "";
	display: block;
	width: 35vw;
  max-width: 145px;
	height: 2px;
	background-color: #777;
	position: absolute;
	right: 0;
}
.arrow_a span::before{
  transform: rotate(15deg);
  transform-origin: right center;
  top: 33%;
}
.arrow_a span::after{
  transform: rotate(165deg);
  transform-origin: right center;
  top: -33%;
  right: 1px;
}
.yuni-cosme_imgContainer{
  position: relative;
  margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
}
.yuni-cosme_imgContainer h2{
  position: absolute;
  top: 42%;
  right: 0;
  left: 0;
  text-align: center;
  line-height: 55px;
  font-size: 32px;
  color: #F06247;
}
.karte{
  display: block;
	width: 100%;
  margin: 0 auto;
	height: auto;
  max-width: 414px;
}
/* ここからwhatYouCanセクション */
.whatYouCan{
  background-color: #F5EAE7;
  position: relative;
}
.whatYouCan_title{
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 3px;
}
.whatYouCan_title p{
  text-align: center;
  padding: 100px 0 100px 0;
}
.whatYouCan_container{
  padding: 150px 0 0 0;
}
.wyc_continue{
  width: 100vw;
  max-width: 414px;
  padding: 100px 0;
}
.wyc_continue span{
  display: block;
  width: 15px;
  height: 15px;
  background-color: #777;
  border-radius: 50%;
  margin: 0 auto;
}
.wyc_continue span:nth-of-type(2){
  display: block;
  width: 15px;
  height: 15px;
  background-color: #777;
  border-radius: 50%;
  margin: 15px auto;
}
.service_explanation_container{
  display: flex;
  flex-direction: column;
  padding: 0 15px 50px 15px;
}
.service_explanation_card{
  display: flex;
  align-items: center;
  margin: 10px 0;
  background-color: #fff;
  box-shadow: 1px 2px 16px -6px rgba(0,0,0,0.6);
}
.coming_soon {
  position: relative;
}
.coming_soon_card {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.service_explanation_iconBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 100px;
}
.service_explanation_iconBox img{
  width: 50px;
  height: 50px;
}
.service_explanation_iconBox p{
  font-size: 11px;
  padding-top: 8px;
  font-weight: 500;
}
.service_explanation{
  flex: 1;
  padding: 0 10px 0 5px;
}
.service_explanation p{
  line-height: 1.5;
}
.wyc_triangle_l{
  background-color: #F5EAE7;
  position: absolute;
  clip-path: polygon(0% 0%, 50% 100%, 0% 100%);
  width: 100vw;
  max-width: 414px;
  height: 30vw;
  bottom: 0;
  z-index: 30;
}
.wyc_triangle_r{
  background-color: #F5EAE7;
  position: absolute;
  clip-path: polygon(100% 0%, 100% 100%, 50% 100%);
  width: 100vw;
  max-width: 414px;
  height: 30vw;
  bottom: 0;
  z-index: 30;
}
.arrow_b{
  position: relative;
  padding-bottom: 10px;

}
.arrow_b span{
  display: block;
	vertical-align: middle;
	position: absolute;
  left: 50%;
}
.arrow_b span::before, .arrow_b span::after{
  content: "";
	display: block;
	width: 30px;
  max-width: 145px;
	height: 2px;
	background-color: #777;
	position: absolute;
	right: 0;
}
.arrow_b span::before{
  transform: rotate(15deg);
  transform-origin: right center;
  top: 33%;
}
.arrow_b span::after{
  transform: rotate(165deg);
  transform-origin: right center;
  top: -33%;
  right: 1px;
}

.mykarte{
  padding: 100px 0 20px 0;
}
.mykarte_explanation{
  text-align: center;
}
.mykarte_explanation h2{
  font-size: 32px;
  font-weight: bold;
}
.mykarte_explanation p{
  font-size: 16px;
  line-height: 25px;
  padding: 10px 0 30px 0; 
}
.mykarte_contents_container{
  position: relative;
}
.mykarte_contents_container img{
  margin: 0 auto;
  display: block;
  width: 80vw;
  max-width: 350px;
}
.mykarte_contents_inner{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 25%;
  right: 0;
  left: 0;
  margin: auto;
}
.mykarte_contents{
  position: relative;
  display: flex;
  padding-bottom: 30px;
  align-items: center;
  width: 60%;
  max-width: 248px;
  margin: 0 auto;
}
.mykarte_contents p{
  padding-left: 20px;
  font-weight: 500;
  width: 80%;
  font-size: 15px;
}
.mykarte_contents p span{
  color: #F06247;
}
.mykarte_contents_imgBox{
  width: 20%;
}
.mykarte_contents_imgBox img{
  width: 40px;
}
.mykarte_comment_container{
  padding-bottom: 200px;
}
.mykarte_comment{
  position:relative;
  width:85vw;
  max-width: 352px;
  background:#F5EAE7;
  height:220px;
  text-align:center;
  color:#777;
  font-size:24px;
  border: solid 2px #E6A393;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mykarte_comment:after,.mykarte_comment:before{
  border: solid transparent;
  content:'';
  height:0;
  width:0;
  pointer-events:none;
  position:absolute;
  bottom:100%;
  left:52%;

}
.mykarte_comment:after{
  border-color: rgba(245, 234, 231, 0);
  border-top-width:10px;
  border-bottom-width:10px;
  border-left-width:30px;
  border-right-width:30px;
  margin-left: -30px;
  border-bottom-color:#F5EAE7;
}
.mykarte_comment:before{
  border-color: rgba(230, 163, 147, 0);
  border-top-width:10px;
  border-bottom-width:10px;
  border-left-width:32px;
  border-right-width:32px;
  margin-left: -32px;
  margin-bottom: 2px;
  border-bottom-color:#E6A393;
}
.mykarte_comment p{
  line-height: 40px;
}
.mykarte_comment p span{
  color: #F06247;
  font-weight: bold;
}
.arrow_c{
  position: relative;
  padding-bottom: 50px;
}
.arrow_c span{
  display: block;
	vertical-align: middle;
	position: absolute;
  left: 50%;
}
.arrow_c span::before, .arrow_c span::after{
  content: "";
	display: block;
	width: 30vw;
  max-width: 124px;
	height: 5px;
	background-color: #E6A393;
	position: absolute;
	right: 0;
}
.arrow_c span::before{
  transform: rotate(15deg);
  transform-origin: right center;
  top: 33%;
}
.arrow_c span::after{
  transform: rotate(165deg);
  transform-origin: right center;
  top: -33%;
  right: 1px;
}
.skinCheck{
  padding:0 0 200px 0;
}
.atFirst{
  width: 100vw;
  max-width: 414px;
  padding: 0 0 150px 0;
  white-space: nowrap;
}
.atFirst p{
  writing-mode: vertical-rl;
  color: #E6A393;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 5px;
  margin: 0 auto;

}
.atFirst span {
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  background-color: #E6A393;
  border-radius: 50%;
  margin: 10px;
}
.skinCheck_container{
  position: relative;
}
.skinCheck_img{
  width: 100vw;
  max-width: 414px;
}
.skinCheck_contents_inner{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24%;
  width: 80%;
  margin: 0 auto;
  right: 0;
  left: 0;
  align-items: center;
}
.skinCheck_contents{
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
  padding: 20px;
  opacity: 0.3;
  /* margin: 0 auto; */
}
.skinCheck_contents:first-child{
  opacity: 1;
  border: 3px solid #E6A393;
  border-radius: 20px;
  margin-bottom: 20px;
  animation: blinkEffect 1s ease infinite alternate;
}
@keyframes blinkEffect {
  0% {
    box-shadow: none;
    opacity: 0.7;
  }
  100% {
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    opacity: 1;
  }
}
.skinCheck_contents_imgBox{
  width: 25%;
  text-align: center;
}
.skinCheck_contents_imgBox img{
  width: 40px;
}
.skinCheck_btn_title{
  width: 80%;
  padding-left: 5px;
  text-align: center; 
  font-size: 20px;
}
.skinCheck_btn_title img{
  width: 35px;
  padding-left: 5px;
  padding-bottom: 5px;
}
.skinCheck_btn{
  font-weight: bold;
  font-size: 26px;
  padding-top: 5px;
}
.skinCheck_btn_link{
  width: 90%;
}



/* ourPromiseセクション */
.ourPromise{
  background-color: #fff;
}
.ourPromise_title{
  color: #FADAD2;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
}
.ourPromise_title p{
  padding: 10px 0 50px 0;
}
.ourPromise_main{
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-bottom: 50px;
}
.ourPromise_main p{
  line-height: 40px;
}
.ourPromise_firstLine, .ourPromise_secondLine{
  border-bottom: solid 3px #E6A393;
}
.ourPromise_bold{
  font-size: 28px;
}
.ourPromise_Explanation{
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.ourPromise_Explanation p{
  font-size: 14px;
  color: #777;
  line-height: 20px;
}
</style>
